import React, {useEffect, useState, useCallback} from 'react';

import ProviderSelector from "../../providerSelector";
import {useStoreActions} from "easy-peasy";
import axios from "axios";
import {
	contributionSettingsCalculateUrl,
	plaidEmployeeTokenUrl
} from "../../../_constants";
import RegisterAccountPopup from "../../popups/registerAccountPopup";
import NumberFormat from "react-number-format";
import InstitutionSelectorPopup from "../../popups/institutionSelectorPopup";
import BankInfoPopup529 from "../../popups/bankInfoPopup";
import LoanAccountPopup from "../../popups/loanAccountPopup";
import ManualLoanOptionPopup from "../../popups/manualOptionPopup";
import ManualSavingsPopup from "../../popups/manualSavingsPopup";
import useSpinwheelDim from '../../../hooks/useSpinwheelDim';
import SpinwheelLoansPopup from '../../popups/spinwheelLoansPopup';
import ContactPopup from '../../popups/contactPopup';

let newSelectedAccountType = '';

export default function RegisterAccountsSlide({changeHandler,authToken,organization,user,contributionSettings}){

	const showMessage = useStoreActions(actions=>actions.alertMessage.setMessage);
	const [plaidHandler,setPlaidHandler] = useState(null);
	const [showNewAccountPopup,setShowNewAccountPopup] = useState(false);
	const [selectedAccountType,setSelectedAccountType] = useState('');
	const [accountsResponse,setAccountsResponse] = useState(null);
	const [totalAllocation,setTotalAllocation] = useState(0);
	const [linkedAccounts,setLinkedAccounts] = useState([]);
	const [showInstitutionPopup,setShowInstitutionPopup] = useState(false);
	const [programContributionAmount,setProgramContributionAmount] = useState('');
	const [show529Popup,setShow529Popup] = useState(false);
	const [showManualLoanPopup,setShowManualLoanPopup] = useState(false);
	const [showManualSavingsPopup,setShowManualSavingsPopup] = useState(false);
	const [connectionItem,setConnectionItem] = useState(null);
	const [showManualOptionPopup,setShowManualOptionPopup] = useState(false);
	const showContactPopup = useStoreActions(actions=>actions.modals.showContact);
	const [showSpinwheelAccountSelect, initSpinwheelDim, closeSpinwheelAccountSelect, spinwheelServicerIds, spinwheelConnectionType] = useSpinwheelDim(authToken,setShowManualOptionPopup);

	const manualOptionCloser = (openAddPopup = false) =>{
		setShowManualOptionPopup(false);
		const accountType = selectedAccountType || newSelectedAccountType;
		if(openAddPopup){
			switch(accountType){
				case 'student':
					initSpinwheelDim(true,true);
					break;
				case 'savings':
					setShowManualSavingsPopup(true);
					break;
				case 'hsa' :
					setShowManualSavingsPopup(true)
				default:
					break;
			}

		}else{
			setTimeout(()=>{document.body.style.overflow = 'scroll';},30);
		}
	}

	const manualLoanPopup = (newConnectionItem = null) =>{
		setShow529Popup(false);
		setShowInstitutionPopup(false);
		setShowNewAccountPopup(false);
		setConnectionItem(newConnectionItem)
		setShowManualLoanPopup(true);

	}
	const manualPopupResponseHandler = (response) =>{
		if(response.data.account){
			setLinkedAccounts([response.data.account, ...linkedAccounts]);
		}
		setShowManualLoanPopup(false);
		setShowManualSavingsPopup(false);
		setTimeout(()=>{document.body.style.overflow = 'scroll';},30);
	};
	const onExit = () => {
		const accountType = selectedAccountType || newSelectedAccountType;
		setSelectedAccountType(accountType);
		if(accountType === 'hsa'){
			setShowManualSavingsPopup(true);
		}else{
			if(organization['organization_allow_manual_savings'] === 'yes') {
				setShowManualOptionPopup(true);
			}
		}
		setTimeout(()=>{document.body.style.overflow = 'scroll';},30);
	};
	const onStudentExit = () => {
		setShowManualOptionPopup(true);
		setTimeout(()=>{document.body.style.overflow = 'scroll';},30);
	};
	const onSuccess = useCallback((token, metadata) => {
		let postData = {
			public_token:token,
			account_type:selectedAccountType
		}
		axios.post('/plaid/accounts',postData,{headers: {'Auth-token': authToken}}).then(response=>{
			setAccountsResponse(response.data);
			setShowNewAccountPopup(true);
		}).catch(e=>{})
	}, [authToken, selectedAccountType]);

	useEffect(()=>{
		const plaidCancelToken = axios.CancelToken.source();
		const accountsToken = axios.CancelToken.source();
		const contributionCalcToken = axios.CancelToken.source();

		axios.post(contributionSettingsCalculateUrl(organization.organization_id,user.user_id),{user_contribution_settings:contributionSettings}, {cancelToken:contributionCalcToken.token,headers: {'Auth-token': authToken}}).then(response=>{
			setProgramContributionAmount(
				Math.round(
					parseFloat(response.data.user_contribution_settings.user_contribution_setting_program_contribution_flat) +
					parseInt(response.data.user_contribution_settings.user_contribution_setting_program_match_flat)
				)
			)
		}).catch(e=>{});
		if(checkForAccountType('emergency')) {
			axios.post(plaidEmployeeTokenUrl, {}, {
				cancelToken: plaidCancelToken.token,
				headers: {'Auth-token': authToken}
			}).then(result => {
				if (result.error != null) {
					showMessage({type: 'error', message: result.error});
					return;
				}
				setPlaidHandler(window.Plaid.create({
					token: result.data.link_token,
					onSuccess: onSuccess,
					onExit: onExit
				}));
			});
		}

		axios.get('/organizations/'+organization.organization_id+'/users/'+user.user_id+'/accounts',{headers: {'Auth-token': authToken}}).then(response=>{
			let existingAccounts = response.data.accounts;
			setLinkedAccounts(existingAccounts);
			let totalAlc = 0;
			existingAccounts.forEach(acc=>{totalAlc += parseInt(acc.account_allocation || 0)});
			setTotalAllocation(totalAlc);
		}).catch(e=>{});
		return ()=>{
			plaidCancelToken.cancel('');
			accountsToken.cancel('');
			contributionCalcToken.cancel('');
		}
	},[]);

	useEffect(()=>{
		if(linkedAccounts && linkedAccounts.length === 1 && linkedAccounts[0].account_allocation == 0){
			updateAccountAllocation(100,linkedAccounts[0]);
		}else {
			changeHandler(linkedAccounts);
		}
	},[linkedAccounts]);

	const plaidButtonHandler = (accountType) =>{
		newSelectedAccountType = accountType;
		setSelectedAccountType(accountType);
		// setShowInstitutionPopup(true);

		plaidHandler.open();
	};

	const registerAccountPopupCloser = (account,institution) =>{
		if(!account || !institution){
			setShowNewAccountPopup(false);
			setAccountsResponse(null);
			showMessage({message:'Could not retrieve account or bank info, please try again later',type:'error'});
			return;
		}
		const postData = {
			account:account,
			item:accountsResponse.item,
			connection_id:accountsResponse.connection_id,
			institution:institution,
			organization_id:organization.organization_id
		};
		axios.post('/plaid/register-account',postData,{headers: {'Auth-token': authToken}}).then(response=>{
			setShowNewAccountPopup(false);
			setAccountsResponse(null);
			setSelectedAccountType(null);
			if(linkedAccounts.filter(acc=>acc.plaid_account_id === response.data.account.plaid_account_id).length>0){
				showMessage({message:'Account Already Listed',type:'error'});
			}else {
				changeHandler([response.data.account, ...linkedAccounts]);
				setLinkedAccounts([response.data.account, ...linkedAccounts]);
			}
			setTimeout(()=>{document.body.style.overflow = 'scroll';},30);
		}).catch(e=>{});

	}

	const getAccountType = (account) =>{

		switch (account.account_type_token) {
			case 'student':
				return 'Student Loan';
			case 'savings':
				return 'Emergency Savings Account';
			case '529':
				return 'College savings plan';
			case 'education savings account':
				return 'Tax-advantaged Education Savings Account (ESA)';
			default:
				return 'Account';
		}
	};

	const checkForAccountType = (typeToken) =>{
		if(!organization){
			return false;
		}
		return organization.organization_account_types.some(at=>at.account_type_token === typeToken);
	};

	const updateAccountAllocation = (value,newAccount) =>{
		let newTotalAllocation = 0;
		let newAccountSettings = linkedAccounts.map(account=>{
			if((account.plaid_account_id && account.plaid_account_id === newAccount.plaid_account_id) || (account.account_id && account.account_id === newAccount.account_id) || (account.spinwheel_account_id && account.spinwheel_account_id === newAccount.spinwheel_account_id)){
				account.account_allocation = value;
			}
			newTotalAllocation += parseInt(account.account_allocation || 0);
			return account;
		});
		setLinkedAccounts(newAccountSettings);
		changeHandler(newAccountSettings);
		setTotalAllocation(newTotalAllocation);
	};

	const institutionPopupCloseHandler = (data) => {
		setShowInstitutionPopup(false);
		if(!data){
			return;
		}
		if(data === 'new'){

			plaidHandler.open();
			return;
		}
		if(data.accounts){
			setAccountsResponse(data);
			setShowNewAccountPopup(true);
		}
		setTimeout(()=>{document.body.style.overflow = 'scroll';},30);
	};

	const popupResponseHandler_529 = (response) =>{
		if(response.data.account){
			setLinkedAccounts([response.data.account, ...linkedAccounts]);
		}
		setShow529Popup(false);
		setTimeout(()=>{document.body.style.overflow = 'scroll';},30);
	};

	const reallocateDeletedAllocation = (deletedAllocation,remainingAccounts) =>{
		let split = Math.round(parseInt(deletedAllocation)/remainingAccounts.length);
		return remainingAccounts.map(account=>{
			account.account_allocation = parseInt(account.account_allocation)+split;
			return account;
		});
	};

	const removeAccount = (account) =>{
		if(!account.account_id){
			let existingAccounts = linkedAccounts.filter(a=>a.plaid_account_id !== account.plaid_account_id)
			existingAccounts = reallocateDeletedAllocation(account.account_allocation,existingAccounts);
			setLinkedAccounts(existingAccounts);
			changeHandler(existingAccounts);
			let totalAlc = 0;
			existingAccounts.forEach(acc=>{totalAlc += parseInt(acc.account_allocation || 0)});
			setTotalAllocation(totalAlc);
			return;
		}
		axios.delete('/organizations/'+organization.organization_id+'/users/'+user.user_id+'/accounts/'+account.account_id,{headers: {'Auth-token': authToken}}).then(response=>{
			let existingAccounts = linkedAccounts.filter(a=>a.account_id !== account.account_id)
			existingAccounts = reallocateDeletedAllocation(account.account_allocation,existingAccounts);
			setLinkedAccounts(existingAccounts);
			changeHandler(existingAccounts);
			let totalAlc = 0;
			existingAccounts.forEach(acc=>{totalAlc += parseInt(acc.account_allocation || 0)});
			setTotalAllocation(totalAlc);

		}).catch(e=>{})
	};

	const handleCloseSpinwheelAccount = async (newAccount, newAccountNumberUpdate = null) => {
		const [newLinkedAccounts, accountsChanged] = await closeSpinwheelAccountSelect(newAccount, linkedAccounts, newAccountNumberUpdate);
		if(!accountsChanged){
			return;
		}
		setLinkedAccounts(newLinkedAccounts);
	}

	return (
	<>
		{show529Popup && <BankInfoPopup529 sucessHandler={popupResponseHandler_529} closeHandler={()=>{setShow529Popup(false)}} organization={organization} user={user} authToken={authToken} />}
		{showInstitutionPopup && <InstitutionSelectorPopup subtype={selectedAccountType} authToken={authToken} closeHandler={institutionPopupCloseHandler} />}
		{showNewAccountPopup && <RegisterAccountPopup addAccountManual={manualLoanPopup} authToken={authToken} small={true} data={accountsResponse} subType={selectedAccountType} closeHandler={registerAccountPopupCloser} />}
		{showManualLoanPopup && <LoanAccountPopup organization={organization} authToken={authToken} subType={selectedAccountType} sucessHandler={manualPopupResponseHandler} closeHandler={()=>{setShowManualLoanPopup(false)}} user={user} />}
		{showManualOptionPopup && <ManualLoanOptionPopup closeHandler={manualOptionCloser} />}
		{showManualSavingsPopup && <ManualSavingsPopup organization={organization} authToken={authToken} subType={selectedAccountType} user={user} sucessHandler={manualPopupResponseHandler} closeHandler={()=>{setShowManualSavingsPopup(false)}} />}
		{showSpinwheelAccountSelect && <SpinwheelLoansPopup closeHandler={handleCloseSpinwheelAccount}
															organization={organization}
															servicerIds={spinwheelServicerIds}
															authToken={authToken}
															triggerBankLogin={()=>{ initSpinwheelDim(true, true) }}
															connectionType={spinwheelConnectionType}/>}
		{showContactPopup && <ContactPopup authToken={authToken} />}
		<div className={'two-column-signup max-width-container'}>

			<div className={'left accounts'}>
				<h1 className={'title with-border'}>Register Accounts</h1>
				<p>
					Register the Accounts where you would like your Thrive allocations to be sent. If you are
					registering more than one account you can allocate the percentage of your benefit to each
					account.
				</p>

			</div>

			<div className={'right'}>
				<div className={'signup-calculator accounts'}>
					<div className={'accounts-header'}>
						<div className={'add-account-holder'}>
							<div className={'add-account-buttons'}>
								{checkForAccountType('529') && <button onClick={()=>{setShow529Popup(true)}} ><span><strong>+</strong> Add 529</span></button>}
								{checkForAccountType('hsa') && <button onClick={()=>{setSelectedAccountType('hsa');setShowManualSavingsPopup(true)}} ><span><strong>+</strong> Add HSA</span></button>}
								{(plaidHandler) &&
									<>
										{checkForAccountType('emergency') && <button onClick={()=>{plaidButtonHandler('savings')}} ><span><strong>+</strong> Add Emergency Savings</span></button>}
									</>
									}
								{checkForAccountType('student') && <button onClick={()=>{initSpinwheelDim(true, true)}}><span><strong>+</strong> Add Student Loan</span></button>}
							</div>
						</div>
					</div>
					{linkedAccounts && linkedAccounts.map((account,index)=>(
						<div key={(account.plaid_account_id|| account.account_id) + index} className={'account-block'}>
							<div className={'account-title'}>
								<h2>{getAccountType(account)}</h2>
								<button onClick={()=>{removeAccount(account)}}>Remove</button>
							</div>

							<div className={'account-details ' +(account['savings_account_verified']?'':'unverified ')}>
								{account.institution_name &&
									<label>
										Provider
										<ProviderSelector inputDisabled={true} isDisplayOnly={true} options={[{label:account.institution_name,value:account.pliad_institution_id,logo:account.plaid_institution_logo?'data:image/png;base64,'+account.plaid_institution_logo:null}]} changeHandler={()=>{}} />
									</label>
								}
								<label>
									Account
									<ProviderSelector inputDisabled={true} isDisplayOnly={true} options={[{label:account.account_name + (account.plaid_account_mask?' ('+account.plaid_account_mask+')':''),value:account.account_name,logo:null}]} changeHandler={()=>{}} />
								</label>
								<label>
									Allocation
									<div className={'input-holder dollar'}>
										<div className={'input'}>
											<NumberFormat value={account.account_allocation}
											              thousandSeparator={true}
											              decimalScale={0}
											              maxLength={3}
											              onValueChange={(values) => {
												              const {formattedValue, value} = values;
												              updateAccountAllocation(value,account);
											              }}
											/>
											<span>%</span>
										</div>
									</div>
								</label>
							</div>
							{!account['savings_account_verified'] &&
							<div style={{marginTop:10}}>
								<span style={{marginLeft:'10px'}}>* 2 micro-deposits will be made to this account and must be confirmed before payments can be made.</span>
							</div>

							}
						</div>
					))}
					{(!linkedAccounts || linkedAccounts.length === 0) &&
					<div className={'account-block empty'}>
						<div>
							<h2>No Accounts Registered</h2>
							<p>Please Connect an Account</p>
						</div>
					</div>

					}
					{linkedAccounts && linkedAccounts.length > 0 &&

					<div className={'contribution-disclosure accounts in-slide'}>
						Contribution Amount Allocated <NumberFormat value={totalAllocation}
						                                            className={totalAllocation !== 100?'error':''}
						                                            thousandSeparator={true}
						                                            decimalScale={0}
						                                            suffix={'%'}
						                                            displayType={'text'}

					/>
					</div>
					}
				</div>
			</div>
		</div>
	</>
	)
}
