import React, {useEffect, useMemo, useState} from 'react';
import Popup from "./popup";
import axios from 'axios';
import {getHttpErrorMessage} from "../../_helpers";
import NumberFormat from "react-number-format";
export default function ManualSavingsPopup({sucessHandler,closeHandler,authToken, organization, user, subType})
{
	const [updateToken,setUpdateToken] = useState(null);
	const [accountRoutingNumber,setAccountRoutingNumber] = useState('');
	const [accountNumber,setAccountNumber] = useState('');
	const [accountNumberConfirmation,setAccountNumberConfirmation] = useState('');
	const [errorFields,setErrorFields] = useState([]);
	const [message,setMessage] = useState('');
	const [accountName,setAccountName] = useState('');
	const [bank,setBank] = useState('')
	const [confirmedBank,setConfirmedBank] = useState('');
	const [isCafPlan,setIsCafPlan] = useState(false);
	const [showMicroDeposits, setShowMicroDeposits] = useState(subType !== 'hsa');
	const [isFamilyAccount, setIsFamilyAccount] = useState(-1);
	const [noMedicare, setNoMedicare] = useState(false);
	const [notDependent, setNotDependent] = useState(false);
	const [screen,setScreen] = useState('account');
	const [hasSeenAlert, setHasSeenAlert] = useState(false);

	const hasErrors = () =>{
		let errors = [];

		if(accountName.trim().length<=0){
			errors.push('accountName');
			setMessage('Account Name required.');
		}
		if(accountRoutingNumber.trim().length<=0){
			errors.push('accountRoutingNumber');
			setMessage('Account Routing Number required.');
		}
		if(bank.trim().length<=0){
			errors.push('bank');
			setMessage('Institution Name required.');
		}

		if(accountNumberConfirmation !== accountNumber){
			errors.push('accountNumberConfirmation');
			setMessage('Account Numbers Must Match.');
		}
		if(accountNumberConfirmation.trim().length<=0){
			errors.push('accountNumberConfirmation');
			setMessage('Confirm Account Number.');
		}
		if(accountNumber.trim().length<=0){
			errors.push('accountNumber');
			setMessage('Account Number Required.');
		}
		if(subType === 'hsa'){
			if(isFamilyAccount === -1){
				errors.push('isFamilyAccount');
				setMessage('You must confirm your eligibility for Health Savings Account (HSA) contributions to ensure compliance with IRS regulations');
			}
			if(!noMedicare){
				errors.push('noMedicare');
				setMessage('You must confirm your eligibility for Health Savings Account (HSA) contributions to ensure compliance with IRS regulations');
			}
			if(!notDependent){
				errors.push('notDependent');
				setMessage('You must confirm your eligibility for Health Savings Account (HSA) contributions to ensure compliance with IRS regulations');
			}

		}

		setErrorFields(errors);
		return errors.length>0;
	};

	const saveHandler = () =>{
		if(hasErrors()){
			return;
		}
		if(subType === 'hsa' && !hasSeenAlert){
			setScreen('hsa_alert');
			setHasSeenAlert(true);
			return;
		}
		
		let postData = {
			account: {
				financial_institute:bank,
				savings_title:accountName,
				savings_account_number: accountNumber,
				savings_account_routing_number: accountRoutingNumber,
				account_type_token: subType === 'hsa' ? 'hsa' : 'emergency',
				account_deposits_required:'yes'
			}
		};
		if(subType === 'hsa'){
			postData.account.savings_account_is_family = isFamilyAccount;
			postData.account.savings_account_no_medicare = noMedicare;
			postData.account.savings_account_not_dependent = notDependent;
			postData.account.savings_account_caf_plan = isCafPlan;
		}
		let config = {};
		if(authToken){
			config.headers = {'Auth-token': authToken}
		}
		axios.post('/organizations/'+organization['organization_id']+'/users/'+user['user_id']+'/add-account',postData, config).then(response=>{
			sucessHandler(response);
		}).catch(e=>{
			setMessage(getHttpErrorMessage(e));
		});
	};

	const listStyle = useMemo(()=>{
		if(subType === 'hsa'){
			return {rowGap:6};
		}
		return {};
	},[subType]);

	const searchRoutingNumber = (routingNumber) =>{
		if(routingNumber.length === 9){
			axios.get('/plaid/search-institutions/'+routingNumber).then(response=>{
				if(!response.data['institution']){
					setConfirmedBank('Routing number could not be confirmed');
					return;
				}
				setConfirmedBank(response.data['institution']['name']);
				setBank(response.data['institution']['name']);
			}).catch(e=>{
				setErrorFields([...errorFields,'accountRoutingNumber']);
				setConfirmedBank('Routing number could not be confirmed');
			});
		}else{
			setErrorFields([...errorFields,'accountRoutingNumber']);
			setConfirmedBank('Routing Number must be 9 digits');
		}
	};

	return (
		<Popup closeHandler={closeHandler}>
			<div className={'large-form popup-content form-slide'}>
				<div className={'title'}>
					<h2><img className={'thrive-logo'} src={'/images/thrive-contribution.svg'} alt={'thrive'} />
					{subType === 'hsa'?'Add Your HSA Account':'Add Your Emergency Savings Account'}
					</h2>

				</div>
				{screen === 'account' &&
				<>
				{showMicroDeposits && 
					<div style={{padding:'20px', textAlign:'center', marginBottom:'20px'}} className={'body'}>
						After Saving your account information two deposits between $0.01 and $0.20 will be made to the account. Come back to this screen after receiving them to verify the account. Payments will not be made to the account until this is done.
					</div>
				}
				<div className={'form-container no-border'}>

					<div className={'form-inner field-list small'} style={listStyle}>

						<label className={'full-row'}>
							Account Name:
							<input className={(errorFields.includes('accountName')?'invalid':'')} value={accountName} onChange={(e)=>{setAccountName(e.target.value)}}/>
						</label>
						<label className={'full-row'}>

							Routing Number: 
							<div style={{width:'100%',position:'relative'}}>
							{(confirmedBank && confirmedBank !== bank) && <span style={{color:'rgba(0,0,0,.5)',position:'absolute',top:-10}}>{confirmedBank}</span>}
							<NumberFormat
								allowLeadingZeros={true}
								className={'nested-input ' + (errorFields.includes('accountRoutingNumber') ? 'invalid' : '')}
								placeholder={'#'}
								decimalScale={0}
								thousandSeparator={false}
								value={accountRoutingNumber}
								onBlur={(e) => {searchRoutingNumber(e.target.value)}}
								onValueChange={(values) => {
									const {value} = values;
									setConfirmedBank('');
									setAccountRoutingNumber(value);

								}} />
							</div>
						</label>
						< label className={'full-row'}>
							Account Number:
							<input className={(errorFields.includes('accountNumber')?'invalid':'')} value={accountNumber} onChange={(e)=>{setAccountNumber(e.target.value)}}/>
						</label>
						<label className={'full-row'}>
							Confirm Account Number:
							<input className={(errorFields.includes('accountNumberConfirmation')?'invalid':'')} value={accountNumberConfirmation} onChange={(e)=>{setAccountNumberConfirmation(e.target.value)}}/>
						</label>
						

						<label className={'full-row'}>
							Financial Institution Name:
							<input  disabled={!confirmedBank}className={(errorFields.includes('bank')?'invalid':'')} value={bank} onChange={(e)=>{setBank(e.target.value)}}/>
						</label>
						{ subType === 'hsa' && 
							<>
								<p style={{gridColumn:'1 / span 2',paddingTop:12,marginTop:12,borderTop:'1px solid rgba(0,0,0,.3)'}}>Please confirm the information below to register your HSA account:</p>
								<label className={'full-row'}>
									<select className={errorFields.includes('isFamilyAccount')?'invalid':''} value={isFamilyAccount} onChange={(e)=>{setIsFamilyAccount(e.target.value)}}>
										<option value={-1}>Select HSA type</option>
										<option value={1}>I am enrolled in a qualified HSA with individual HDHP coverage</option>
										<option value={0}>I am enrolled in a qualified HSA with family HDHP Coverage</option>
									</select>
								</label>
				
								<label className={'full-row'}>
									<div className={'check-holder '  + (errorFields.includes('noMedicare')?'invalid':'')} onClick={() => {
										setNoMedicare(!noMedicare);
									}}>
										
										<span className={'checkbox ' + (noMedicare ? 'checked ' : ' ')}/>
										I am not enrolled in Medicare.
									</div>
								</label>
								<label className={'full-row'}>
									<div className={'check-holder '  + (errorFields.includes('notDependent')?'invalid':'')} onClick={() => {
										setNotDependent(!notDependent);
									}}>
										
										<span className={'checkbox ' + (notDependent ? 'checked ' : ' ')}/>
										I am not claimed as a dependent on someone else's tax return.
									</div>
								</label>

							</>
						}
						<div className={'save-row full-row'}>
							<button  className={'simple-rounded-button blue'} onClick={(e)=>{saveHandler()}}><span>Add Account</span></button>
							{message && <p className={'popup-form-error'}>{message}</p>}
						</div>
					</div>
				</div>
				</>
				}
				{screen === 'hsa_alert' &&
				<>
					<div className={'form-inner field-list small'} style={listStyle}>
						<p className={'full-row'}>
							By entering your banking details and authorizing the transaction, you acknowledge and agree that they cannot be reclaimed or reversed once the funds have been sent. You are responsible for double-checking and confirming that the bank account information you provided is accurate
						</p>
						<div className={'save-row full-row'}>
							<button style={{margin:'auto'}} className={'simple-rounded-button blue'} onClick={(e)=>{saveHandler()}}><span>Continue</span></button>
						</div>
						<div className={'save-row full-row'}>
							<button style={{margin:'auto'}} className={'simple-rounded-button hollow'} onClick={(e)=>{setHasSeenAlert(false);setScreen('account')}}><span>Go Back</span></button>
						</div>
					</div>
				</>
				}
			</div>
		</Popup>
	)
}
